


html::-webkit-scrollbar {
  width: 12px; 
  height: 8px; 
}

html::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(124, 124, 124, 0.5);
  border-radius: 8px; 
  border: 2px solid rgba(255, 255, 255, 0.1);
}

html::-webkit-scrollbar-thumb:hover {
  background-color: rgba(130, 130, 130, 0.7); 
}

html::-webkit-scrollbar-button {
  display: none; 
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


