.change_photo_size
{
    width: 50%;
}


@media (min-width: 200px) and (max-width: 700px) 
{
  .change_photo_size
  {
      width: 70%;
  }
}  


@media (min-width: 200px) and (max-width: 700px) 
{
  .change_photo_size
  {
      width: 70%;
  }


  .textInCenter
  {
    text-align: center;
  }

}  

