

  
  .navbar-custom {
    font-size: 20px;
    margin-right: 15px;
    color: gray;
    text-decoration: none !important;
  
  }
  
  .navbar-custom:hover {
    color: gainsboro; 
  }


  .navbar-language
  {
    font-size: 20px;
    margin-right: 15px;
    color: rgb(0, 0, 0); 
  }

  .navbar-dropdown
  {
    padding: 0px 0px 0px 0px !important;
  }



.dropdown-links1
{
  display:"block";width:"120%";height:1000px;
}

  .active-link {
    color: gainsboro; 
  }

  .active-link2 {
    color: rgb(0, 0, 0); 
  }


  .nav-dropdown-item {
    padding: 5px 10px; 
  }

  .size-of-logo-img
  {
    width: 75px;
  }

  @media (min-width: 992px) and (max-width: 1000px) {
    .navbar-custom {
      font-size: 14px;
      margin-right: 3px;
    }

    

  }

  @media (min-width: 1001px) and (max-width: 1083px) {
    .navbar-custom {
      font-size: 16px;
      margin-right: 3px;
    }


    /* .size-of-logo-img
    {
      width: 10px;
    } */
  }

  @media (min-width: 1084px) and (max-width: 1295px) {
    .navbar-custom {
      font-size: 17px;
      margin-right: 3px;
    }
  }


  @media (min-width: 1296px) and (max-width: 1301px) {
    .navbar-custom {
      font-size: 17px;
      margin-right: 3px;
    }




  }

 
  