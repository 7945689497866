.smaller-screen-size-of-div
{
    width: 60%;
}



@media (min-width: 200px) and (max-width: 758px) 
{
    .smaller-screen-size-of-div
    {
        width: 100%;
    }
  
}  


