.screen-size {
    height: 300px; 
  }

  

.size-game-hidden-cards-lvl1
{
  height:750px;
}


.size-game-puzzle-lvl1
{
  height: 800px; 
}



.size-game-cross-lvl1
{
 height: 800px;
}



  @media (min-width: 401px) and (max-width: 767px) 
  {
    .screen-size {
      height: 290px; 
    }

    .size-game-puzzle-lvl1
{
  height: 500px; 
}
    
  }  


  @media (min-width: 200px) and (max-width: 400px) 
  {

    .screen-size {
      height: 290px; 
    }

    .size-game-puzzle-lvl1
    {
      height: 300px; 
    }


    .size-game-hidden-cards-lvl1
{
  height:1220px;
}


.size-game-cross-lvl1
{
 height: 450px;
}

    
  }  
  


/* /////////////////////// LVL2 */


.screen-size {
  height: 300px; 
}


.size-game-hidden-cards-lvl2
{
height:825px;
}


.size-game-puzzle-lvl2
{
height: 900px; 
}



.size-game-cross-lvl2
{
height: 900px;
}



@media (min-width: 401px) and (max-width: 767px) 
{
  .screen-size {
    height: 290px; 
  }

  .size-game-puzzle-lvl2
  {
height: 500px; 
}
  
}  


@media (min-width: 200px) and (max-width: 400px) 
{

  .screen-size {
    height: 290px; 
  }

  .size-game-puzzle-lvl2
  {
    height: 300px; 
  }


  .size-game-hidden-cards-lvl2
{
height:1220px;
}


.size-game-cross-lvl2
{
height: 450px;
}

  
}  



/* /////////////////////// LVL3 */


.screen-size {
  height: 300px; 
}


.size-game-hidden-cards-lvl3
{
height:1072px;
}


.size-game-puzzle-lvl3
{
height: 500px; 
}



.size-game-cross-lvl3
{
height: 900px;
}



@media (min-width: 401px) and (max-width: 767px) 
{
  .screen-size {
    height: 300px; 
  }

  .size-game-puzzle-lvl3
  {
height: 300px; 
}
  
}  


@media (min-width: 200px) and (max-width: 400px) 
{

  .screen-size {
    height: 290px; 
  }

  .size-game-puzzle-lvl3
  {
    height: 300px; 
  }


  .size-game-hidden-cards-lvl3
{
height:1220px;
}


.size-game-cross-lvl3
{
height: 450px;
}

  
}  
