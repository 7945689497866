.size-map-home
{
    width: 100%;
}

.size-map-contact-us
{
    width: 60%;
}



@media (max-width: 768px) {
    .size-map-home {
        width: 100%;
    }


    .size-map-contact-us
    {
    width: 60%;
    }


}




/* @media (max-width: 1000px) and (min-width: 768px) {
    .size-map-home {
        width: 100%;
    }
} */





