.right-logos-home
{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 50px !important;
}

.size-of-logos-right
{
  height: 200px;
}




 .size-of-Depantiqu {
  max-width: 100%;
  height: auto;   
  display: block;  
  margin: 0 auto; 
  margin-top: 10px;
  cursor: pointer;
}

.size-of-locations
{
  max-width: 90%;
  height: auto;   
  display: block;  
  margin: 0 auto; 
  margin-top: 10px;
  cursor: pointer;
}


@media (min-width: 601px) and (max-width: 1199px) 
{
  .size-of-locations {
    width: 40%; 
  }

  .size-of-locations {
    width: 80%; 
  }
}


@media (max-width: 600px) {
  .size-of-locations {
    width: 80%; 
  }

  .size-of-Depantiqu
  {
    width: 80%;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  .size-of-logos-right
{
  height: 140px;
}


}





@media (min-width: 300px) and (max-width: 1200px) {
  
  .center-right-logos
  {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }



 
  .right-logos-home
{
  display: flex !important;
  flex-direction:row !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 50px !important;
}
}



@media (min-width: 300px) and (max-width: 1200px) {
    .margins-for-smaller-screens {
      margin-bottom: 20px !important;
    }
  }


  @media (min-width: 300px) and (max-width: 600px) {
    .padding-for-smaller-screens {
      padding: 20px !important;
      /* text-align: center; */
    }
  }


  @media (min-width: 300px) and (max-width: 1200px) {
    

    .center-div-small-screen
{
  display: flex;
  justify-content: center;
}

    .textAlign-small-screen
    {
      text-align: center !important;
    }
  }