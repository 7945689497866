/* .col1
{
    border: solid 3px red
} */

/* .col2
{
    border: solid 2px rgb(0, 96, 240)
} */


 .col3
 {
    border: solid 1px rgb(255,255, 255);
 }
