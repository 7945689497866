.change_the_size_of_text
{
    width: 30%;
}


@media (min-width: 200px) and (max-width: 1151px) 
{
  .change_the_size_of_text
  {
      width: 100%;
  }
}  




#sizeOfVirtualTour
{
    width: 1053px;
    height: 630px;
}



@media (min-width: 700px) and (max-width: 1073px) {
    
    
    #sizeOfVirtualTour
    {
        width: 680px;
        height: 580px;
    }

}


@media (min-width: 600px) and (max-width: 699px) {
    
    
    #sizeOfVirtualTour
    {
        width: 580px;
        height: 580px;
    }

}



@media (min-width: 310px) and (max-width: 599px) {
    
    
    #sizeOfVirtualTour
    {
        width: 310px;
        height: 480px;
    }

}

