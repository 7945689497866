@media (max-width: 768px) {
    .marginTop-location_video {
        margin-top: 60px !important;
    }
}


.button-adj-width
{
    width: 320px;
}


.video-adj-width
{
    width: 75%;
}




@media (max-width: 450px) {
    .center-text-titles {
        text-align: center;
    }

    .button-adj-width
{
    width: 90%
}

.video-adj-width
{
    width: 95%;
}


}

