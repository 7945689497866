
.image-list-item {
    position: relative;
    overflow: hidden;
  }
  
  .image-list-item  {
    transition: filter 0.3s ease;
  }
  
  .image-list-item:hover {
    filter: brightness(0.65);
  }

 