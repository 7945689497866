
@keyframes scroll
{
    0%
    {
        transform: translateX(0);
    }
    100%
    {
        transform: translateX(calc(-250px * 28));
    }
}


@keyframes scroll2
{
    0%
    {
        transform: translateX(0);
    }
    100%
    {
        transform: translateX(calc(-250px * 38));
    }
}

@keyframes scroll3
{
    0%
    {
        transform: translateX(0);
    }
    100%
    {
        transform: translateX(calc(-250px * 18));
    }
}




.slider-container
{
    height: 250px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow-x: hidden;
}

.slide-images
{
    display: flex;
    animation: scroll 70s linear infinite;
}

.slide-images2
{
    display: flex;
    animation: scroll2 75s linear infinite;
}

.slide-images3
{
    display: flex;
    animation: scroll3 40s linear infinite;
}




.slide-images:hover
{
    animation-play-state:paused
}

.slide-images2:hover
{
    animation-play-state:paused
}

.slide-images3:hover
{
    animation-play-state:paused
}



.slide-single-image
{
    height: 250px;
    width: 350px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}

.images
{
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    transition: transform 1s;
}

.images:hover
{
    transform: scale(1.1);
    cursor: pointer;
}




.slider-container::before,
.slider-container::after
{
    background: linear-gradient(to right, rgba(255,255,255,1) 0%
    , rgba(255,255,255,0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 5%;
    z-index: 2;
}

.slider-container::before
{
    left: 0;
    top: 0;
}

.slider-container::after
{
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}


.sizesss
{
    width:260px;
    height:260px; 
    border-radius: 10px;
}

@media (min-width: 1535px) {
    .sizesss {
        width: 230px;
        height: 230px;
    }
}


@media (min-width: 1200px) and (max-width: 1535px) {
    .sizesss {
        width: 270px;
        height: 270px;
    }
}


@media (min-width: 900px) and (max-width: 1200px) {
    .sizesss {
        width: 250px;
        height: 250px;
    }
}

@media (min-width: 768px) and (max-width: 899px) {
    .sizesss {
        width: 350px;
        height: 350px;
    }
}

@media (max-width: 599px){
    .sizesss {
        width: 350px;
        height: 350px;
    }
}








/* You might already have appropriate styles, adjust as necessary */
.modal img {
    transition: opacity 0.5s ease;
  }
  
  .arrow-button {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }
  