.video-container {
    max-width: 100%;
  }
  
  .video-wrap {
    padding-top: 50%;
    position: relative;
  }


  .video-wrap iframe {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }