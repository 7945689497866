.flip-card-saint-neophytos {
    background-color: transparent;
    width: 350px;
    height: 470px;
    perspective: 1000px;
    margin-top: 30px;
  }

  .img-size-saint-neophytos 
  {
    cursor: pointer;
    width: 350px;
    height: 470px;
  }
  
  .flip-card-inner-saint-neophytos  {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
  }
  
  .flip-card-saint-neophytos:hover .flip-card-inner-saint-neophytos  {
    transform: rotateY(180deg);
  }
  
  .flip-card-front-saint-neophytos , .flip-card-back-saint-neophytos  {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  
  
  .flip-card-back-saint-neophytos  {
    cursor: pointer;
    background-image: url("../assets/img/Saint_Neophytos/pic93.jpg");
    background-size: 350px 470px; 
    background-repeat: no-repeat; 
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotateY(180deg);
    border-radius: 20px;
  }


  

 

  @media screen and (max-width: 300px) {
    .flip-card-saint-neophytos  {
      width: 200px;
      height: 200px;
    }

    .flip-card-back-saint-neophytos  {
        cursor: pointer;
        background-image: url("../assets/img/Saint_Neophytos/pic93.jpg");
        background-size: 200px 200px; 
        background-repeat: no-repeat; 
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transform: rotateY(180deg);
      }


   

      .img-size-saint-neophytos 
      {
        cursor: "pointer";
        height: 250px;
        width: 200px;
      }
}




/*  */


.flip-card-saint-neophytos-monastery {
    background-color: transparent;
    width: 350px;
    height: 470px;
    perspective: 1000px;
    margin-top: 30px;
    
  }

  .img-size-saint-neophytos-monastery 
  {
    cursor: pointer;
    width: 350px;
    height: 470px;
    
  }
  
  .flip-card-inner-saint-neophytos-monastery  {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
  }
  
  .flip-card-saint-neophytos-monastery:hover .flip-card-inner-saint-neophytos-monastery  {
    transform: rotateY(180deg);
  }
  
  .flip-card-front-saint-neophytos-monastery , .flip-card-back-saint-neophytos-monastery  {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    
  }
  
  
  
  .flip-card-back-saint-neophytos-monastery  {
    cursor: pointer;
    background-image: url("../assets/img/Saint_Neophytos/pic52.jpg");
    background-size: 350px 470px; 
    background-repeat: no-repeat; 
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotateY(180deg);
    border-radius: 20px;
  }


  

 

  @media screen and (max-width: 300px) {
    .flip-card-saint-neophytos-monastery  {
      width: 200px;
      height: 200px;
    }

    .flip-card-back-saint-neophytos-monastery  {
        cursor: pointer;
        background-image: url("../assets/img/Saint_Neophytos/pic52.jpg");
        background-size: 200px 200px; 
        background-repeat: no-repeat; 
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transform: rotateY(180deg);
      }


   

      .img-size-saint-neophytos-monastery 
      {
        cursor: "pointer";
        height: 250px;
        width: 200px;
      }
}



/*  */




.flip-card-englistra {
    background-color: transparent;
    width: 350px;
    height: 470px;
    perspective: 1000px;
    margin-top: 30px;
  }

  .img-size-englistra
  {
    cursor: pointer;
    width: 350px;
    height: 470px;
  }
  
  .flip-card-inner-englistra  {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
  }
  
  .flip-card-englistra:hover .flip-card-inner-englistra  {
    transform: rotateY(180deg);
  }
  
  .flip-card-front-englistra , .flip-card-back-englistra  {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  
  
  .flip-card-back-englistra  {
    cursor: pointer;
    background-image: url("../assets/img/Saint_Neophytos/pic57.jpg");
    background-size: 350px 470px; 
    background-repeat: no-repeat; 
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotateY(180deg);
    border-radius: 20px;
  }


  

 

  @media screen and (max-width: 300px) {
    .flip-card-englistra  {
      width: 200px;
      height: 200px;
    }

    .flip-card-back-englistra  {
        cursor: pointer;
        background-image: url("../assets/img/Saint_Neophytos/pic57.jpg");
        background-size: 200px 200px; 
        background-repeat: no-repeat; 
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transform: rotateY(180deg);
      }


   

      .img-size-englistra
      {
        cursor: "pointer";
        height: 250px;
        width: 200px;
      }
}