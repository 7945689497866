  
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
   
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    border: 2px solid gray; 
    background-color: transparent;
    margin-right: 10px; 
    transition: background-color 0.3s; 
  }


  #twitterIcon
  {
    color:gray;
  }

  #facebookIcon
  {
    color:gray;
  }

  #youtubeIcon
  {
    color:gray;
  }

  .social-icon:hover
  {
    background-color: rgb(231, 231, 231);
  }

  #twitterIcon:hover
  {
    color:#1DA1F2;
  }

  #facebookIcon:hover
  {
    color:#316FF6;
  }

  #youtubeIcon:hover
  {
    color:#FF0000;
  }