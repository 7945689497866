.flip-card {
    background-color: transparent;
    width: 300px;
    height: 450px;
    perspective: 1000px;
  }

  .img-size
  {
    cursor: pointer;
    height: 450px;
    width: 300px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  
  
  .flip-card-back {
    cursor: pointer;
    background-image: url("../assets/img/ebook_cover.jpg");
    background-size: 300px 450px; 
    background-repeat: no-repeat; 
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotateY(180deg);
  }



  .margin-smaller-screen-ebook
  {
    margin-bottom: 0px;
  }
  
  @media (min-width: 200px) and (max-width: 899px) {
    
    
    .margin-smaller-screen-ebook
    {
      margin-bottom: 15px;
    }

}
 

  @media screen and (max-width: 300px) {
    .flip-card {
      width: 200px;
      height: 200px;
    }

    .flip-card-back {
        cursor: pointer;
        background-image: url("../assets/img/ebook_cover.jpg");
        background-size: 200px 200px; 
        background-repeat: no-repeat; 
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transform: rotateY(180deg);
      }


   

      .img-size
      {
        cursor: "pointer";
        height: 250px;
        width: 200px;
      }
}